<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="my-6">
                        <v-app-bar color="indigo" dark flat dense>
                            <v-app-bar-title>Accounts <span v-if="list">({{list.length}})</span></v-app-bar-title>
                            <v-spacer/>
                            <v-btn icon @click="openCreateAccountDialog">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                            </v-btn>
                        </v-app-bar>
                        <template v-if="isViewReady">
                        <v-list dense nav>
                            <v-list-item v-if="list.length === 0">No accounts.</v-list-item>
                            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                                <AccountListItem :attr="item" :link="!selectOne"></AccountListItem>
                            </v-list-item>
                        </v-list>
                        <CreateAccountDialog v-model="createAccountDialogVisible" @cancel="createAccountDialogVisible = false" @created="onCreatedAccount"/>
                        </template>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import AccountListItem from '@/components/AccountListItem.vue';
import CreateAccountDialog from '@/components/user-dashboard/CreateAccountDialog.vue';
// import { isValidName, compact } from '@/sdk/input';

export default {
    components: {
        AccountListItem,
        CreateAccountDialog,
    },
    data: () => ({
        list: null,
        createAccountDialogVisible: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            focus: (state) => state.focus,
        }),
        id() {
            return this.policyId;
        },
        label() {
            if (this.selectOne) {
                return 'Select an account';
            }
            switch (this.list.length) {
            case 0:
                return 'No accounts';
            case 1:
                return '1 account';
            default:
                return `${this.list.length} accounts`;
            }
        },
        isViewReady() {
            return Array.isArray(this.list);
        },
    },
    watch: {
        displayCreateAccount(value) {
            if (value && (this.add || this.create)) {
                this.initAddAccountChoices();
            }
        },
    },

    methods: {
        async loadAccountList() {
            try {
                this.$store.commit('loading', { loadAccountList: true });
                const response = await this.$client.user(this.user.id).account.search();
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadAccountList failed', err);
            } finally {
                this.$store.commit('loading', { loadAccountList: false });
            }
        },
        onClickItem(accountId) {
            if (this.selectOne) {
                this.$emit('selected', { accountId });
            }
        },
        openCreateAccountDialog() {
            this.createAccountDialogVisible = true;
        },
        async onCreatedAccount() {
            this.createAccountDialogVisible = false;
            this.loadAccountList();
        },
        /*
        async createAccount() {
            this.error = false;
            console.log('createAccount');
            const request = {
                // name: this.name,
                name: this.newAccountName,
                // agreeToTerms: this.isAgreeToTermsChecked,
                // interactionId: this.interactionId, // will be present if account arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the account to an appropriate location after account is created
            };
            const response = await this.$client.account.create(request);
            console.log('createAccount response: %o', response);
            const { isCreated, id, error } = response;
            if (isCreated) {
                const account = { id, ...request };
                this.list.push(account);
                this.$emit('created-account', account);
                this.$emit('added-account', account);
                this.displayCreateAccount = false;
                this.newAccountName = null;
            } else if (error) {
                this.error = error;
            } else {
                this.error = 'Request failed';
            }
        },
        validateCreateAccount() {
            if (this.$refs.createAccountFormRef.validate()) {
                this.createAccount();
            }
        },
        async addAccount() {
            const account = { id: this.addAccountId, label: this.addAccountChoices.find((item) => item.value === this.addAccountId).text };
            this.list.push(account);
            this.$emit('added-account', account);
            this.displayCreateAccount = false;
            this.addAccountId = null;
        },
        onSubmitForm() {
            if (this.submitFormTimestamp !== null && this.submitFormTimestamp + 500 > Date.now()) {
                console.log('bounced!');
                return;
            }
            this.submitFormTimestamp = Date.now();
            if (!this.$refs.createAccountFormRef.validate()) {
                return;
            }
            if (this.add) {
                this.addAccount();
            } else if (this.create) {
                this.createAccount();
            } else {
                console.error('account list misconfigured; either add or create is required to use the dialog');
            }
        },
        */
        async initAddAccountChoices() {
            // create a map of which account ids are already in the list
            const accountMap = {};
            this.list.forEach((item) => {
                accountMap[item.id] = true;
            });
            // when we show the add/create account dialog, load the list of available accounts to add to the policy (list of all accounts less the ones already in the policy)
            const result = await this.$client.account.search();
            console.log(`AccountViewList.vue fetch accounts result: ${JSON.stringify(result)}`);
            if (result && result.list) {
                // filter the results to remove accounts already in the policy, then convert the available accounts to a choice list for the v-select [ { text, value }, ... ]
                this.addAccountChoices = result.list.filter((item) => !accountMap[item.id]).map((item) => ({ text: item.name, value: item.id }));
            }
        },
    },

    mounted() {
        this.loadAccountList();
    },
};
</script>
